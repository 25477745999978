import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import FaceIcon from "@mui/icons-material/Face";

import Header from "../components/Header";
import { FormEvent, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth";
import axios from "axios";
import { Service, User } from "../../context/auth/types";
import GoogleAddressInput from "../components/GoogleAddressInput";
import ImageUploadInput from "../components/ImageUploadInput";
import {
  INSTAGRAM_USERNAME_REGEX,
  LINKEDIN_PROFILE_REGEX,
} from "../../constants";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Router";

const CaregiverProfile: React.FC<{ isClient?: boolean }> = ({ isClient }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { user, updateUser } = useContext(AuthContext);

  const [updateErr, setUpdateErr] = useState<string | null>(null);
  const [allServices, setAllServices] = useState<Service[]>([]);
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);
  const [uptUser, setUptUser] = useState<User | null>(user);
  const [updated, setUpdated] = useState<boolean>(false);
  const [firstNameErr, setFirstNameErr] = useState<string>("");
  const [lastNameErr, setLastNameErr] = useState<string>("");
  const [addressObj, setAddressObj] = useState<{
    address: string;
    latitude: number;
    longitude: number;
  }>({
    address: "",
    latitude: 0,
    longitude: 0,
  });
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [shortBioErr, setShortBioErr] = useState<string>("");
  const [instagramErr, setInstagramErr] = useState<string>("");
  const [linkedinErr, setLinkedinErr] = useState<string>("");

  useEffect(() => {
    if (user) {
      setUptUser(user);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      if (isClient) {
        setSelectedServices(user.looking_for_services || []);
      } else {
        setSelectedServices(user.services || []);
        setAddressObj({
          address: user.address || "",
          latitude: user.latitude || 0,
          longitude: user.longitude || 0,
        });
      }
    }
  }, [user, isClient]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API}/services`
        );

        const services = data.data.map((service: any) => ({
          id: service.id,
          ...service.attributes,
        }));

        setAllServices(services);
      } catch (err: any) {
        console.error(err);
      }
    };

    fetchServices();
  }, [setAllServices]);

  const handleUpload = async () => {
    if (!selectedImage) return;

    const formData = new FormData();

    formData.append("files", selectedImage);

    const response = await axios.post(
      `${process.env.REACT_APP_API}/upload`,
      formData
    );

    const data = response.data;

    return data?.[0];
  };

  const clearErrors = () => {
    setFirstNameErr("");
    setLastNameErr("");
    setShortBioErr("");
    setInstagramErr("");
    setLinkedinErr("");
    setUpdateErr(null);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    clearErrors();

    if (!uptUser?.first_name) {
      return setFirstNameErr(
        intl.formatMessage({ id: "general.FirstNameIsRequired" })
      );
    }

    if (!uptUser?.last_name) {
      return setLastNameErr(
        intl.formatMessage({ id: "general.LastNameIsRequired" })
      );
    }

    if ((uptUser?.short_bio?.length ?? 0) > 80) {
      return setShortBioErr(
        intl.formatMessage({
          id: "general.ShortBioMaxLength",
          defaultMessage: "Short bio max length is 80 characters",
        })
      );
    }

    if (uptUser?.instagram) {
      if (!INSTAGRAM_USERNAME_REGEX.test(String(uptUser?.instagram))) {
        return setInstagramErr(
          intl.formatMessage({
            id: "general.InvalidInstagramUsername",
            defaultMessage: "Invalid Instagram username",
          })
        );
      }
    }

    if (uptUser?.linkedin) {
      if (!LINKEDIN_PROFILE_REGEX.test(String(uptUser?.linkedin))) {
        return setLinkedinErr(
          intl.formatMessage({
            id: "general.InvalidLinkedInProfile",
            defaultMessage: "Invalid LinkedIn profile",
          })
        );
      }
    }

    try {
      let avatar = null;

      if (!isClient && selectedImage) {
        avatar = await handleUpload();
      }

      const payload: any = {
        ...uptUser,
      };

      if (avatar) {
        payload.avatar = avatar;
      }

      if (isClient) {
        payload.looking_for_services = selectedServices.map(
          (service) => service.id
        );
      } else {
        payload.services = selectedServices.map((service) => service.id);
      }

      payload.address = addressObj.address;
      payload.latitude = addressObj.latitude;
      payload.longitude = addressObj.longitude;

      const response = await axios.put(
        `${process.env.REACT_APP_API}/users/me`,
        payload
      );

      const data = response.data;

      updateUser({
        ...user,
        ...data,
      });
      setUpdated(true);

      setTimeout(() => {
        navigate(ROUTES.SCHEDULE);
      }, 2000);
    } catch (err: any) {
      const errMsg = err.response?.data?.error?.message;

      console.error(err);

      return setUpdateErr(
        intl.formatMessage(
          {
            id: "general.SomethingWentWrong",
            defaultMessage: "Something went wrong: {error}",
          },
          { error: errMsg }
        )
      );
    }
  };

  const handleUserFieldChange = (field: string, value: any) => {
    if (!uptUser) return null;

    setUpdateErr(null);
    setUpdated(false);

    setUptUser({
      ...uptUser,
      [field]: value,
      id: uptUser?.id ?? 0,
    });
  };

  const renderAvatar = () => {
    if (uptUser?.avatar) {
      return (
        <Avatar
          sizes="large"
          sx={{ m: 1, bgcolor: "primary.main", width: 100, height: 100 }}
          src={uptUser?.avatar?.url}
        />
      );
    }

    return (
      <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
        <FaceIcon />
      </Avatar>
    );
  };

  if (!uptUser) {
    return null;
  }

  return (
    <Box>
      <Header />
      <Typography variant="h3" align="center" marginTop={2}>
        <FormattedMessage id="general.Profile" defaultMessage="Profile" />
      </Typography>

      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: "30px",
          }}
        >
          {renderAvatar()}

          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label={
                <FormattedMessage id="general.Email" defaultMessage="Email" />
              }
              name="email"
              autoComplete="email"
              autoFocus
              disabled
              value={uptUser?.email || ""}
              onChange={(e) => {
                setUptUser({
                  ...uptUser,
                  email: e.target.value,
                });
              }}
            />

            <TextField
              margin="normal"
              fullWidth
              id="first_name"
              label={
                <FormattedMessage
                  id="general.FirstName"
                  defaultMessage="FirstName"
                />
              }
              name="first_name"
              autoComplete="first_name"
              autoFocus
              value={uptUser?.first_name || ""}
              onChange={(e) => {
                handleUserFieldChange("first_name", e.target.value);
                setFirstNameErr("");
              }}
              error={Boolean(firstNameErr)}
              helperText={firstNameErr}
            />

            <TextField
              margin="normal"
              fullWidth
              id="last_name"
              label={
                <FormattedMessage
                  id="general.LastName"
                  defaultMessage="LastName"
                />
              }
              name="last_name"
              autoComplete="last_name"
              autoFocus
              value={uptUser?.last_name || ""}
              onChange={(e) => {
                handleUserFieldChange("last_name", e.target.value);
                setLastNameErr("");
              }}
              error={Boolean(lastNameErr)}
              helperText={lastNameErr}
            />

            {!isClient && (
              <>
                <Box
                  sx={{
                    marginTop: "10px",
                  }}
                >
                  <GoogleAddressInput
                    defaultValue={uptUser?.address}
                    language={user?.language}
                    onPlaceSelected={(place: any) => {
                      setAddressObj(place);

                      handleUserFieldChange("address", place.formatted_address);
                    }}
                  />
                </Box>
                <ImageUploadInput
                  value={selectedImage?.name || uptUser?.avatar?.name || ""}
                  label={intl.formatMessage({
                    id: "general.ProfileImage",
                    defaultMessage: "Profile Image",
                  })}
                  style={{
                    marginTop: "20px",
                  }}
                  onChange={(e) => {
                    if (e.target.files) {
                      setSelectedImage(e.target.files[0]);
                    }
                  }}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  id="short_bio"
                  label={
                    <FormattedMessage
                      id="general.ShortBio"
                      defaultMessage="Short Bio"
                    />
                  }
                  name="short_bio"
                  autoComplete="short_bio"
                  value={uptUser?.short_bio || ""}
                  onChange={(e: any) => {
                    if (e.target.value.length > 80) {
                      setShortBioErr(
                        intl.formatMessage({
                          id: "general.ShortBioMaxLength",
                          defaultMessage:
                            "Short bio max length is 80 characters",
                        })
                      );
                    } else {
                      setShortBioErr("");
                    }

                    handleUserFieldChange("short_bio", e.target.value);
                  }}
                  error={Boolean(shortBioErr)}
                  helperText={shortBioErr}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  id="instagram"
                  label={
                    <FormattedMessage
                      id="general.InstagramUsername"
                      defaultMessage="Instagram Username"
                    />
                  }
                  name="instagram"
                  autoComplete="instagram"
                  value={uptUser?.instagram || ""}
                  onChange={(e) => {
                    handleUserFieldChange("instagram", e.target.value);
                  }}
                  error={Boolean(instagramErr)}
                  helperText={instagramErr}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  id="linkedin"
                  label={
                    <FormattedMessage
                      id="general.LinkedInProfile"
                      defaultMessage="LinkedIn Profile"
                    />
                  }
                  name="linkedin"
                  autoComplete="linkedin"
                  value={uptUser?.linkedin || ""}
                  onChange={(e) => {
                    handleUserFieldChange("linkedin", e.target.value);
                  }}
                  error={Boolean(linkedinErr)}
                  helperText={linkedinErr}
                />
              </>
            )}

            <TextField
              margin="normal"
              fullWidth
              id="phone"
              label={
                <FormattedMessage id="general.Phone" defaultMessage="Phone" />
              }
              name="phone"
              autoComplete="phone"
              value={uptUser?.phone || ""}
              onChange={(e) => {
                const regex = /^\+?[0-9\s-()]*$/;

                if (!regex.test(e.target.value)) return;

                handleUserFieldChange("phone", e.target.value);
              }}
            />
            <Autocomplete
              multiple
              id="services"
              options={allServices}
              getOptionLabel={(option) => option.name}
              value={selectedServices || []}
              disableCloseOnSelect
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  label={
                    <FormattedMessage
                      id="general.ServicesSelectAllThatApply"
                      defaultMessage="Services (select all that apply)"
                    />
                  }
                />
              )}
              onChange={(event, value) => {
                setSelectedServices(value);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              clearText={intl.formatMessage({
                id: "general.Clear",
                defaultMessage: "Clear",
              })}
              noOptionsText={intl.formatMessage({
                id: "general.NoOptions",
                defaultMessage: "No options",
              })}
            />
            {Boolean(updateErr) && (
              <Typography style={{ color: "red" }}>{updateErr}</Typography>
            )}
            {updated && (
              <Typography style={{ color: "green" }}>
                <FormattedMessage
                  id="general.ProfileUpdatedYouWillBeRedirected"
                  defaultMessage="Profile updated. You will be redirected to the schedule page."
                />
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 2,
              }}
            >
              <FormattedMessage id="general.Update" defaultMessage="Update" />
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CaregiverProfile;
