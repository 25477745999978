import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { AddCircle } from "@mui/icons-material";
import axios from "axios";
import qs from "qs";

import Header from "../components/Header";
import TimesheetModal from "./modal";
import { User } from "../../context/auth/types";
import { Timesheet } from "./types";
import dayjs from "dayjs";

const TimesheetsPage = () => {
  const intl = useIntl();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [clients, setClients] = useState<User[]>([]);
  const [timesheets, setTimesheets] = useState<Timesheet[]>([]);
  const [pagination, setPagination] = useState<{
    page: number;
    perPage: number;
    pageCount: number;
    total: number;
  }>({
    page: 1,
    perPage: 10,
    pageCount: 1,
    total: 0,
  });

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const data = await axios.get(
          `${process.env.REACT_APP_API}/timesheet/caregiver-clients`
        );

        setClients([...data.data]);
      } catch (err: any) {
        console.error(err);
      }
    };

    fetchClients();
  }, []);

  const { page, perPage } = pagination;
  const fetchTimesheets = useCallback(async () => {
    const qsString = qs.stringify({
      pagination: {
        page,
        perPage,
      },
    });

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/timesheets?${qsString}`
      );

      const { results, pagination: newPagination } = response.data;

      setPagination({
        page,
        perPage,
        pageCount: newPagination.pageCount,
        total: newPagination.total,
      });

      setTimesheets(
        results.map((timesheet: any) => {
          return {
            ...timesheet,
          };
        })
      );
    } catch (err: any) {
      console.error(err);
    }
  }, [page, perPage]);

  useEffect(() => {
    fetchTimesheets();
  }, [fetchTimesheets]);

  return (
    <Box>
      <Header />
      <Container sx={{ padding: "20px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h4" component="h6">
            <FormattedMessage
              id="general.Timesheets"
              defaultMessage="Timesheets"
            />
          </Typography>

          <Tooltip
            title={<FormattedMessage id="general.Add" defaultMessage="Add" />}
          >
            <IconButton
              color="primary"
              component="label"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <AddCircle fontSize="large" />
            </IconButton>
          </Tooltip>
        </Box>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="general.Date" defaultMessage="Date" />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="general.StartTime"
                    defaultMessage="Start Time"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="general.EndTime"
                    defaultMessage="End Time"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="general.Client"
                    defaultMessage="Client"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {timesheets.map((timesheet) => {
                return (
                  <TableRow key={timesheet.id}>
                    <TableCell>
                      {dayjs(timesheet.date).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      {dayjs(timesheet.start_time).format("HH:mm")}
                    </TableCell>
                    <TableCell>
                      {dayjs(timesheet.end_time).format("HH:mm")}
                    </TableCell>
                    <TableCell>
                      {timesheet?.client?.first_name}{" "}
                      {timesheet?.client?.last_name}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  count={pagination.total}
                  rowsPerPage={pagination.perPage}
                  page={pagination.page - 1}
                  onPageChange={(_event, page) => {
                    setPagination({
                      ...pagination,
                      page: page + 1,
                    });
                  }}
                  onRowsPerPageChange={(event) => {
                    setPagination({
                      ...pagination,
                      perPage: parseInt(event.target.value),
                    });
                  }}
                  labelDisplayedRows={({ from, to, count }) => {
                    if (intl.locale === "en") {
                      return `${from}-${to} of ${count}`;
                    } else {
                      return `${from}-${to} de ${count}`;
                    }
                  }}
                  labelRowsPerPage={
                    <FormattedMessage
                      id="general.RowsPerPage"
                      defaultMessage="Rows per page"
                    />
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Container>
      <TimesheetModal
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
        clients={clients}
        onSubmit={() => {
          fetchTimesheets();
        }}
      />
    </Box>
  );
};

export default TimesheetsPage;
