import * as enLocale from "./en.json";
import * as frLocale from "./fr.json";

export enum LanguageCode {
  en = "en",
  fr = "fr",
}

const languages = {
  [LanguageCode.en]: enLocale,
  [LanguageCode.fr]: frLocale,
};

export default languages;
