import { Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useContext } from "react";
import { LayoutContext } from "../../context/layout";
import { FormattedMessage } from "react-intl";
import { LanguageCode } from "../../languages";

const PublicFooter = () => {
  const { language, setLanguage } = useContext(LayoutContext);
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        padding: "15px",
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Button
        sx={{
          color: theme.palette.primary.contrastText,
          fontWeight: 700,
        }}
        onClick={() => {
          if (language === LanguageCode.en) {
            setLanguage(LanguageCode.fr);
          } else {
            setLanguage(LanguageCode.en);
          }
        }}
      >
        {language === "en" ? (
          <FormattedMessage id="general.French" />
        ) : (
          <FormattedMessage id="general.English" />
        )}
      </Button>
    </Box>
  );
};

export default PublicFooter;
