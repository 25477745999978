import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import PawsomeLogo from "../../assets/img/White/logo.png";

const PublicHeader = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        display: "flex",
        justifyContent: "center",
        padding: "0 15px",
        width: "100%",
      }}
    >
      <img
        src={PawsomeLogo}
        alt="Pawsome logo"
        style={{
          width: 300,
          maxWidth: "100%",
        }}
      />
    </Box>
  );
};

export default PublicHeader;
