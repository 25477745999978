import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

const ConfirmDialog: React.FC<{
  confirmHandler: () => void;
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  text: string;
}> = (props) => {
  const { confirmHandler, openModal, setOpenModal, text } = props;

  return (
    <Dialog
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <FormattedMessage
          id="general.ConfirmTheAction"
          defaultMessage="Confirm the action"
        />
      </DialogTitle>
      <DialogContent>
        <Typography>{text}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            setOpenModal(false);
          }}
          color="primary"
          variant="text"
        >
          <FormattedMessage id="general.Cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            confirmHandler();
          }}
          color="primary"
          variant="contained"
        >
          <FormattedMessage id="general.Confirm" defaultMessage="Confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
