import { LanguageCode } from "../../languages";
import { Availability } from "../../pages/Schedule/types";

export type Service = {
  id: number;
  name: string;
};

export enum UserRoleType {
  CAREGIVER = "caregiver",
  CLIENT = "client",
}

export type UserRole = {
  id: number;
  name: string;
  type: UserRoleType;
};

export type Image = {
  id: number;
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats: {
    small: {
      ext: string;
      url: string;
      hash: string;
      mime: string;
      name: string;
      path: string;
      size: number;
      width: number;
      height: number;
    };
    thumbnail: {
      ext: string;
      url: string;
      hash: string;
      mime: string;
      name: string;
      path: string;
      size: number;
      width: number;
      height: number;
    };
  };
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: string;
  provider: string;
  provider_metadata: string;
  folderPath: string;
  createdAt: string;
  updatedAt: string;
};

export type User = {
  id: number;
  blocked: boolean;
  confirmed: boolean;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  language: LanguageCode;
  token: string;
  address: string;
  distance?: number;
  phone: string;
  services: Service[];
  role: UserRole;
  looking_for_services: Service[];
  latitude?: number;
  longitude?: number;
  availabilities?: Availability[];
  booked?: boolean;
  avatar?: Image;
  short_bio?: string;
  linkedin?: string;
  instagram?: string;
};

export type AuthState = {
  loaded: boolean;
  isLoggedIn: boolean;
  user: User | null;
};

export type AuthAction =
  | { type: "LOGIN"; payload: { user: User } }
  | { type: "LOGOUT" }
  | { type: "UPDATE_USER"; payload: { user: User } };
