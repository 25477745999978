import React, { FormEvent, useContext, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { LockOutlined } from "@mui/icons-material";
import axios from "axios";

import MuiLink from "../components/MuiLink";
import { ROUTES } from "../../Router";
import { AuthContext } from "../../context/auth";
import PublicHeader from "../components/PublicHeader";
import PublicFooter from "../components/PublicFooter";
import { FormattedMessage, useIntl } from "react-intl";

const Login: React.FC<{ role: string }> = ({ role }) => {
  const intl = useIntl();
  const { login } = useContext(AuthContext);
  const isClient = role === "client";
  // const isCaregiver = role === "caregiver";

  const [loginErr, setLoginErr] = useState<string>("");
  const [emailValidationErr, setEmailValidationErr] = useState<string>("");
  const [passwordErr, setPasswordErr] = useState<string>("");

  const loginRequest = async (data: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/auth/local`,
        data
      );

      const { jwt, user } = response.data;
      const me = await axios.get(`${process.env.REACT_APP_API}/users/me`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      const meData = me.data;

      login({
        ...user,
        ...meData,
        token: jwt,
      });
    } catch (e: any) {
      const errMsg = e.response?.data?.error?.message;

      if (errMsg === "Invalid identifier or password") {
        return setLoginErr(
          intl.formatMessage({
            id: "general.InvalidCredentials",
            defaultMessage: "Invalid credentials",
          })
        );
      }

      console.error(e);

      return setLoginErr(
        intl.formatMessage(
          {
            id: "general.SomethingWentWrong",
            defaultMessage: "Something went wrong: {error}",
          },
          { error: errMsg }
        )
      );
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get("email");
    const password = data.get("password");

    if (!email) {
      return setEmailValidationErr(
        intl.formatMessage({
          id: "general.EmailIsRequired",
          defaultMessage: "Email is required",
        })
      );
    }

    if (!password) {
      return setPasswordErr(
        intl.formatMessage({
          id: "general.PasswordIsRequired",
          defaultMessage: "Password is required",
        })
      );
    }

    loginRequest({
      identifier: email,
      password,
    });
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <PublicHeader />
      <Container
        component="main"
        maxWidth="xs"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "space-between",
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Box>
          {/* This box is used to align the form in the center of the page. */}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: "30px",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            {isClient ? (
              <FormattedMessage
                id="general.ClientLogin"
                defaultMessage="Client Login"
              />
            ) : (
              <FormattedMessage
                id="general.CaregiverLogin"
                defaultMessage="Caregiver Login"
              />
            )}{" "}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={
                <FormattedMessage id="general.Email" defaultMessage="Email" />
              }
              name="email"
              autoComplete="email"
              autoFocus
              error={Boolean(emailValidationErr)}
              helperText={emailValidationErr}
              onChange={() => setEmailValidationErr("")}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={
                <FormattedMessage
                  id="general.Password"
                  defaultMessage="Password"
                />
              }
              type="password"
              id="password"
              autoComplete="current-password"
              error={Boolean(passwordErr)}
              helperText={passwordErr}
              onChange={() => setPasswordErr("")}
            />
            {Boolean(loginErr) && (
              <Typography style={{ color: "red" }}>{loginErr}</Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 2,
              }}
            >
              <FormattedMessage id="general.Login" defaultMessage="Login" />
            </Button>
            <MuiLink variant="body2" to={`${ROUTES.SIGN_UP}/${role}`}>
              <FormattedMessage
                id="general.DontHaveAccount"
                defaultMessage="Don't have an account?"
              />{" "}
              <FormattedMessage id="general.SignUp" defaultMessage="Sign Up" />
            </MuiLink>
          </Box>
        </Box>
        <Box textAlign="center" mb={2}>
          <MuiLink variant="body2" to={ROUTES.FORGOT_PASSWORD}>
            <FormattedMessage
              id="general.IForgotMyPassword"
              defaultMessage="I forgot my password"
            />
          </MuiLink>
        </Box>
      </Container>
      <PublicFooter />
    </Box>
  );
};

export default Login;
