import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import EventIcon from "@mui/icons-material/Event";
import dayjs from "dayjs";

import { AuthContext } from "../../context/auth";
import { MobileDatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import { Availability } from "./types";
import ConfirmDialog from "../components/ConfirmDialog";

export interface AvailabilityModalProps {
  date: dayjs.Dayjs;
  openModal: boolean;
  closeModal: () => void;
  onSubmit?: () => void;
  extAvailability?: Availability | null;
}

const AvailabilityModal: React.FC<AvailabilityModalProps> = ({
  date,
  openModal,
  closeModal,
  onSubmit,
  extAvailability,
}) => {
  const intl = useIntl();
  const isEdit = Boolean(extAvailability);
  const { user } = useContext(AuthContext);
  const emptyAvailability: Availability = {
    start_date: date,
    end_date: date,
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [availability, setAvailability] =
    useState<Availability>(emptyAvailability);
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(date);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  useEffect(() => {
    if (extAvailability) {
      setSelectedDate(extAvailability.start_date);
      setAvailability(extAvailability);
    } else {
      setSelectedDate(date);
      setAvailability((prev) => ({
        ...prev,
        start_date: date.startOf("day"),
        end_date: date.endOf("day"),
      }));
    }
  }, [date, extAvailability]);

  const handleCloseModal = () => {
    setAvailability(emptyAvailability);
    closeModal();
  };

  const validate = () => {
    let hasErrors = false;

    return !hasErrors;
  };

  const createAvailability = async () => {
    setLoading(true);

    try {
      await axios.post(`${process.env.REACT_APP_API}/availabilities`, {
        data: {
          ...availability,
          start_date: availability.start_date.format("YYYY-MM-DD"),
          end_date: availability.end_date.format("YYYY-MM-DD"),
          caregiver: user?.id || null,
        },
      });

      onSubmit?.();

      handleCloseModal();
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const updateAvailability = async () => {
    setLoading(true);

    try {
      await axios.put(
        `${process.env.REACT_APP_API}/availabilities/${availability.id}`,
        {
          data: {
            ...availability,
            start_date: availability.start_date.toISOString(),
            end_date: availability.end_date.toISOString(),
            caregiver: user?.id || null,
          },
        }
      );

      onSubmit?.();

      handleCloseModal();
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);

    try {
      await axios.delete(
        `${process.env.REACT_APP_API}/availabilities/${availability.id}`
      );

      onSubmit?.();

      handleCloseModal();
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!validate()) return;

    if (isEdit) {
      await updateAvailability();
    } else {
      await createAvailability();
    }
  };

  const renderDialogContent = () => {
    if (loading) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            minHeight: 200,
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Box component="form" onSubmit={handleSubmit}>
        <Grid
          style={{
            width: 400,
            maxWidth: "100%",
          }}
        >
          <Grid item xs={12} mt={2}>
            <MobileDatePicker
              slots={{
                textField: (params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      endAdornment: <EventIcon />,
                    }}
                  />
                ),
              }}
              label={
                <FormattedMessage id="general.From" defaultMessage="From" />
              }
              value={selectedDate}
              onChange={(newValue) => {
                if (newValue) {
                  setSelectedDate(newValue);
                  setAvailability({
                    ...availability,
                    start_date: newValue,
                  });
                }
              }}
              className="default-picker-full-width"
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <MobileDatePicker
              slots={{
                textField: (params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      endAdornment: <EventIcon />,
                    }}
                  />
                ),
              }}
              label={<FormattedMessage id="general.To" defaultMessage="To" />}
              value={availability.end_date}
              onChange={(newValue) => {
                if (newValue) {
                  setAvailability({
                    ...availability,
                    end_date: newValue,
                  });
                }
              }}
              className="default-picker-full-width"
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Dialog open={openModal} onClose={handleCloseModal}>
      <DialogTitle>
        {isEdit ? (
          <FormattedMessage
            id="general.EditAvailability"
            defaultMessage="Edit Availability"
          />
        ) : (
          <FormattedMessage
            id="general.AddAvailability"
            defaultMessage="Add Availability"
          />
        )}
      </DialogTitle>
      <DialogContent>{renderDialogContent()}</DialogContent>
      <DialogActions>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box>
            {isEdit && (
              <Button onClick={() => setOpenConfirmModal(true)} color="error">
                <FormattedMessage id="general.Delete" defaultMessage="Delete" />
              </Button>
            )}
          </Box>
          <Box>
            <Button onClick={handleCloseModal}>
              <FormattedMessage id="general.Cancel" defaultMessage="Cancel" />
            </Button>
            <Button onClick={handleSubmit} color="primary" variant="contained">
              <FormattedMessage id="general.Submit" defaultMessage="Submit" />
            </Button>
          </Box>
        </Box>
      </DialogActions>
      <ConfirmDialog
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          setOpenConfirmModal(false);
          handleDelete();
        }}
        text={intl.formatMessage({
          id: "general.AreYouSureYouWantToDeleteThisAvailability",
          defaultMessage: "Are you sure you want to delete this availability?",
        })}
      />
    </Dialog>
  );
};

export default AvailabilityModal;
