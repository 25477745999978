import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import dayjs from "dayjs";

import { Timesheet } from "./types";
import { AuthContext } from "../../context/auth";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { User } from "../../context/auth/types";
import axios from "axios";
import ConfirmDialog from "../components/ConfirmDialog";

export interface TimesheetModalProps {
  openModal: boolean;
  closeModal: () => void;
  onSubmit?: () => void;
  clients: User[];
}

const TimesheetModal: React.FC<TimesheetModalProps> = ({
  openModal,
  closeModal,
  onSubmit,
  clients,
}) => {
  const intl = useIntl();
  const { user } = useContext(AuthContext);
  const emptyTimesheet: Timesheet = {
    start_time: dayjs(),
    end_time: dayjs(),
    date: dayjs(),
    description: "",
    client: null,
    caregiver: user?.id || 0,
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [timesheet, setTimesheet] = useState<Timesheet>(emptyTimesheet);
  const [clientRequiredErr, setClientRequiredErr] = useState<boolean>(false);
  const [descriptionRequiredErr, setDescriptionRequiredErr] =
    useState<boolean>(false);
  const [datesErrMsg, setDatesErrMsg] = useState<string>("");
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const handleCloseModal = () => {
    setTimesheet(emptyTimesheet);
    setOpenConfirmModal(false);
    closeModal();
  };

  const validateTimesheet = () => {
    let hasErrors = false;

    if (!timesheet.client) {
      setClientRequiredErr(true);

      hasErrors = true;
    }

    if (!timesheet.description) {
      setDescriptionRequiredErr(true);

      hasErrors = true;
    }

    if (timesheet.start_time.isAfter(timesheet.end_time)) {
      setDatesErrMsg(
        intl.formatMessage({
          id: "general.StartTimeAfterEndTime",
          defaultMessage: "Start time must be before end time",
        })
      );

      hasErrors = true;
    } else if (timesheet.start_time.isSame(timesheet.end_time)) {
      setDatesErrMsg(
        intl.formatMessage({
          id: "general.StartTimeCannotBeTheSame",
          defaultMessage: "Start time cannot be the same as end time",
        })
      );

      hasErrors = true;
    }

    return !hasErrors;
  };

  const handleSubmit = async () => {
    if (!validateTimesheet()) return;

    setLoading(true);

    try {
      await axios.post(`${process.env.REACT_APP_API}/timesheets`, {
        data: {
          ...timesheet,
          date: timesheet.date.toISOString(),
          start_time: timesheet.start_time.toISOString(),
          end_time: timesheet.end_time.toISOString(),
          caregiver: user?.id || null,
          client: timesheet.client?.id || null,
        },
      });

      onSubmit?.();

      handleCloseModal();
    } catch (err: any) {
      console.error(err);
    }

    setLoading(false);
  };

  const renderDialogContent = () => {
    if (loading) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            minHeight: 200,
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Box component="form" onSubmit={handleSubmit}>
        <Grid
          style={{
            width: 400,
            maxWidth: "100%",
          }}
        >
          <Grid item xs={12} mt={2}>
            <MobileDatePicker
              slots={{
                textField: (params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      endAdornment: <EventIcon />,
                    }}
                  />
                ),
              }}
              label={
                <FormattedMessage id="general.Date" defaultMessage="Date" />
              }
              value={timesheet.date}
              onChange={(newValue) => {
                let newStartTime = timesheet.start_time;
                let newEndTime = timesheet.end_time;

                if (newValue) {
                  newStartTime = newStartTime
                    .set("year", newValue.year())
                    .set("month", newValue.month())
                    .set("date", newValue.date());

                  newEndTime = newEndTime
                    .set("year", newValue.year())
                    .set("month", newValue.month())
                    .set("date", newValue.date());
                }

                setTimesheet({
                  ...timesheet,
                  date: newValue || dayjs(),
                  start_time: newStartTime,
                  end_time: newEndTime,
                });
              }}
              className="default-picker-full-width"
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <MobileTimePicker
              slots={{
                textField: (params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      endAdornment: <AccessTimeIcon />,
                    }}
                  />
                ),
              }}
              label={
                <FormattedMessage
                  id="general.StartTime"
                  defaultMessage="Start Time"
                />
              }
              value={timesheet.start_time}
              onChange={(newValue) => {
                setDatesErrMsg("");
                setTimesheet({
                  ...timesheet,
                  start_time: newValue || dayjs(),
                });
              }}
              className="default-picker-full-width"
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <MobileTimePicker
              slots={{
                textField: (params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      endAdornment: <AccessTimeIcon />,
                    }}
                    error={Boolean(datesErrMsg)}
                    helperText={datesErrMsg}
                  />
                ),
              }}
              label={
                <FormattedMessage
                  id="general.EndTime"
                  defaultMessage="End Time"
                />
              }
              value={timesheet.end_time}
              onChange={(newValue) => {
                setDatesErrMsg("");
                setTimesheet({
                  ...timesheet,
                  end_time: newValue || dayjs(),
                });
              }}
              className="default-picker-full-width"
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <Autocomplete
              id="client"
              options={clients}
              getOptionLabel={(option) => {
                if (!option.first_name || !option.last_name)
                  return option.email;

                return `${option.first_name} ${option.last_name}`.trim();
              }}
              value={timesheet.client}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <FormattedMessage
                      id="general.Client"
                      defaultMessage="Client"
                    />
                  }
                  error={clientRequiredErr}
                  helperText={
                    clientRequiredErr ? (
                      <FormattedMessage
                        id="general.Required"
                        defaultMessage="Required"
                      />
                    ) : (
                      ""
                    )
                  }
                />
              )}
              onChange={(event, value) => {
                setClientRequiredErr(false);
                setTimesheet({
                  ...timesheet,
                  client: value,
                });
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              clearText={intl.formatMessage({
                id: "general.Clear",
                defaultMessage: "Clear",
              })}
              noOptionsText={intl.formatMessage({
                id: "general.NoOptions",
                defaultMessage: "No options",
              })}
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <TextField
              required
              fullWidth
              multiline
              minRows={3}
              id="description"
              label={
                <FormattedMessage
                  id="general.Description"
                  defaultMessage="Description"
                />
              }
              name="description"
              autoComplete="description"
              autoFocus
              value={timesheet.description}
              onChange={(e) => {
                setDescriptionRequiredErr(false);
                setTimesheet({
                  ...timesheet,
                  description: e.target.value,
                });
              }}
              error={descriptionRequiredErr}
              helperText={
                descriptionRequiredErr ? (
                  <FormattedMessage
                    id="general.Required"
                    defaultMessage="Required"
                  />
                ) : (
                  ""
                )
              }
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Dialog
      open={openModal}
      onClose={() => {
        handleCloseModal();
      }}
    >
      <DialogTitle>
        <FormattedMessage
          id="general.AddTimesheet"
          defaultMessage="Add Timesheet"
        />
      </DialogTitle>
      <DialogContent>{renderDialogContent()}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleCloseModal();
          }}
        >
          <FormattedMessage id="general.Cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          onClick={() => {
            if (!validateTimesheet()) return;

            setOpenConfirmModal(true);
          }}
        >
          <FormattedMessage id="general.Add" defaultMessage="Add" />
        </Button>
      </DialogActions>
      <ConfirmDialog
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          handleSubmit();
        }}
        text={intl.formatMessage({
          id: "general.OnceSubmittedCannotBeEdited",
          defaultMessage:
            "Once submitted, timesheets cannot be edited. Are you sure you want to submit?",
        })}
      />
    </Dialog>
  );
};

export default TimesheetModal;
