import React, { useRef, useState } from "react";
import {
  Box,
  Container,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import Badge from "@mui/material/Badge";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import WorkOutlineIcon from "@mui/icons-material/Work";
import { FormattedMessage } from "react-intl";
import { AddCircle } from "@mui/icons-material";
import axios from "axios";
import qs from "qs";

import Header from "../components/Header";
import AvailabilityModal from "./modal";
import { Availability, DayAvailabilityMap } from "./types";

const initialScheduleDate = dayjs();

const AvailableDay = (
  props: PickersDayProps<Dayjs> & {
    highlightedDays?: string[];
    openAvailabilityModal?: (date: Dayjs) => void;
  }
) => {
  const theme = useTheme();
  const {
    highlightedDays = [],
    day,
    outsideCurrentMonth,
    openAvailabilityModal,
    ...other
  } = props;

  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.indexOf(props.day.format("YYYYMMDD")) !== -1;

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={
        isSelected ? (
          <WorkOutlineIcon
            style={{ color: theme.color.redWine, fontSize: 18 }}
          />
        ) : undefined
      }
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        style={{
          border: isSelected ? `1px solid ${theme.color.redWine}` : undefined,
        }}
        onClick={() => openAvailabilityModal?.(day)}
      />
    </Badge>
  );
};

const SchedulePage = () => {
  const requestAbortController = useRef<AbortController | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedDays, setHighlightedDays] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [availabilityModalDate, setAvailabilityModalDate] = useState<Dayjs>(
    dayjs()
  );
  const [dayAvailabilityMap, setDayAvailabilityMap] =
    useState<DayAvailabilityMap>({});
  const [extAvailability, setExtAvailability] = useState<Availability | null>(
    null
  );

  const fetchAvailabilities = async (date: Dayjs) => {
    // Cancel previous request if there is one
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }

    const controller = new AbortController();
    requestAbortController.current = controller;

    try {
      const qsString = qs.stringify({
        filters: {
          start_date: {
            $gte: date
              .subtract(1, "month")
              .startOf("month")
              .format("YYYY-MM-DD"),
          },
          end_date: {
            $lte: date.add(1, "month").endOf("month").format("YYYY-MM-DD"),
          },
        },
        pagination: {
          page: 1,
          pageSize: 100,
        },
      });

      // Introduce a delay of 200ms before making the request
      await new Promise((resolve) => setTimeout(resolve, 200));

      const response = await axios.get(
        `${process.env.REACT_APP_API}/availabilities?${qsString}`,
        {
          signal: controller.signal,
        }
      );

      const availabilities: Availability[] = response.data.results.map(
        (avlb: any) => {
          return {
            ...avlb,
            start_date: dayjs(avlb.start_date),
            end_date: dayjs(avlb.end_date),
          };
        }
      );

      const newDayAvailabilityMap: DayAvailabilityMap = {};
      const daysToHighlight: string[] = [];

      availabilities.forEach((availability: any) => {
        const startDate = dayjs(availability.start_date);
        const endDate = dayjs(availability.end_date);

        let currentDate = startDate;

        if (startDate.isSame(endDate, "day")) {
          newDayAvailabilityMap[startDate.format("YYYYMMDD")] = availability;
          daysToHighlight.push(startDate.format("YYYYMMDD"));
          return;
        }

        while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
          const day = currentDate.format("YYYYMMDD");

          newDayAvailabilityMap[day] = availability;
          daysToHighlight.push(day);

          currentDate = currentDate.add(1, "day");
        }
      });

      setDayAvailabilityMap(newDayAvailabilityMap);
      setHighlightedDays(daysToHighlight);
      setIsLoading(false);
    } catch (error: any) {
      // ignore the error if it's caused by `controller.abort`
      if (error.code === "ERR_CANCELED") return;

      console.error("error", error);

      throw error;
    }
  };

  React.useEffect(() => {
    fetchAvailabilities(initialScheduleDate);

    return () => requestAbortController.current?.abort();
  }, []);

  const handleMonthChange = (date: Dayjs) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }

    setIsLoading(true);
    setHighlightedDays([]);
    fetchAvailabilities(date);
  };

  return (
    <Box>
      <Header />
      <Container sx={{ padding: "20px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h4" component="h6">
            <FormattedMessage id="general.Schedule" defaultMessage="Schedule" />
          </Typography>

          <Tooltip
            title={<FormattedMessage id="general.Add" defaultMessage="Add" />}
          >
            <IconButton
              color="primary"
              component="label"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <AddCircle fontSize="large" />
            </IconButton>
          </Tooltip>
        </Box>
        <Typography align="center" component="h6">
          <FormattedMessage
            id="general.CalendarExplanation"
            defaultMessage="Please select the days you are available to accept bookings on the calendar below"
          />
        </Typography>
        <DateCalendar
          defaultValue={initialScheduleDate}
          loading={isLoading}
          onMonthChange={handleMonthChange}
          renderLoading={() => <DayCalendarSkeleton />}
          slots={{
            day: AvailableDay,
          }}
          slotProps={{
            day: {
              highlightedDays,
              openAvailabilityModal: (date: Dayjs) => {
                setAvailabilityModalDate(date);

                const availability =
                  dayAvailabilityMap[date.format("YYYYMMDD")];

                setExtAvailability(availability || null);

                setOpenModal(true);
              },
            } as any,
          }}
          sx={{
            "& .MuiPickersDay-root": {
              "&.Mui-selected": {
                backgroundColor: "#fff",
                color: "#000",
              },
              "&.Mui-disabled": {
                color: "#000",
              },
              "&.Mui-selected:focus": {
                backgroundColor: "#fff",
                color: "#000",
              },
              "&:not(.Mui-selected)": {
                border: "none",
              },
            },
          }}
        />
      </Container>
      <AvailabilityModal
        date={availabilityModalDate}
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
        onSubmit={() => {
          fetchAvailabilities(availabilityModalDate);
        }}
        extAvailability={extAvailability}
      />
    </Box>
  );
};

export default SchedulePage;
