import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import axios from "axios";
import qs from "qs";
import dayjs from "dayjs";
import EventIcon from "@mui/icons-material/Event";
import FaceIcon from "@mui/icons-material/Face";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

import Header from "../components/Header";
import { Availability } from "../Schedule/types";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Service, User } from "../../context/auth/types";
import BookCaregiverModal from "./BookCaregiverModal";
import { AuthContext } from "../../context/auth";
import GoogleAddressInput from "../components/GoogleAddressInput";

const FullWidthCenterCell = ({ children }: { children: React.ReactNode }) => {
  return (
    <TableRow>
      <TableCell colSpan={5} align="center">
        {children}
      </TableCell>
    </TableRow>
  );
};

const FindMyCaregiver = () => {
  const intl = useIntl();
  const { user } = useContext(AuthContext);
  const [availabilities, setAvailabilities] = useState<User[]>([]);
  const [allServices, setAllServices] = useState<Service[]>([]);
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);
  const [pagination, setPagination] = useState<{
    page: number;
    perPage: number;
    pageCount: number;
    total: number;
  }>({
    page: 1,
    perPage: 10,
    pageCount: 1,
    total: 0,
  });
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs());
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(
    dayjs().add(7, "day").startOf("day")
  );
  const [location, setLocation] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedCaregiver, setSelectedCaregiver] = useState<User | null>(null);
  const [hideIrrelevantCaregivers, setHideIrrelevantCaregivers] =
    useState<boolean>(true);
  const [addressObj, setAddressObj] = useState<{
    address: string;
    latitude: number;
    longitude: number;
  }>({
    address: "",
    latitude: 0,
    longitude: 0,
  });
  const [radius, setRadius] = useState<number>(20);

  useEffect(() => {
    if (
      user?.looking_for_services?.length &&
      !selectedServices.length &&
      hideIrrelevantCaregivers
    ) {
      setSelectedServices(user.looking_for_services);
    }
  }, [
    user?.looking_for_services,
    selectedServices.length,
    hideIrrelevantCaregivers,
  ]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API}/services`
        );

        const services = data.data.map((service: any) => ({
          id: service.id,
          ...service.attributes,
        }));

        setAllServices(services);
      } catch (err: any) {
        console.error(err);
      }
    };

    fetchServices();
  }, [setAllServices]);

  const { page, perPage } = pagination;
  const searchCaregivers = useCallback(async () => {
    if (
      !addressObj.address ||
      (selectedServices.length === 0 && hideIrrelevantCaregivers)
    ) {
      return;
    }

    const searchQuery = {
      pagination: {
        page,
        perPage,
      },
      filters: {} as any,
    };

    if (startDate) {
      searchQuery.filters.start_date = startDate.format("YYYY-MM-DD");
    }

    if (endDate) {
      searchQuery.filters.end_date = endDate.format("YYYY-MM-DD");
    }

    if (selectedServices.length > 0) {
      searchQuery.filters.services = selectedServices.map(
        (service) => service.id
      );
    }

    if (addressObj.address) {
      searchQuery.filters.latitude = addressObj.latitude;
      searchQuery.filters.longitude = addressObj.longitude;
      searchQuery.filters.radius = radius;
    }

    const qsString = qs.stringify(searchQuery);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/availabilities/search?${qsString}`
      );

      const { results, pagination: newPagination } = response.data;

      setPagination({
        page,
        perPage,
        pageCount: newPagination.pageCount,
        total: newPagination.total,
      });

      setAvailabilities(
        results.map((availability: any) => {
          return {
            ...availability,
            start_date: dayjs(availability.start_date),
            edn_date: dayjs(availability.edn_date),
          };
        })
      );

      setHideIrrelevantCaregivers(false);
    } catch (err: any) {
      console.error(err);
    }
  }, [
    page,
    perPage,
    startDate,
    endDate,
    selectedServices,
    hideIrrelevantCaregivers,
    addressObj,
    radius,
  ]);

  useEffect(() => {
    searchCaregivers();
  }, [searchCaregivers]);

  const formatAvailabilityDate = (availability: Availability) => {
    const start = dayjs(availability.start_date).format("MM/DD/YYYY");
    const end = dayjs(availability.end_date).format("MM/DD/YYYY");

    if (start === end) {
      return start;
    }

    return `${start} - ${end}`;
  };

  const renderDates = (availabilities: Availability[]) => {
    const count = availabilities.length;

    if (count > 3) {
      const allTooltip = availabilities.map((availability) =>
        formatAvailabilityDate(availability)
      );

      return (
        <Tooltip
          title={
            <Box>
              {allTooltip.map((date) => (
                <Box key={date}>{date}</Box>
              ))}
            </Box>
          }
          placement="bottom-start"
          enterTouchDelay={0}
        >
          <Box>
            {availabilities.slice(0, 2).map((availability) => (
              <Box key={availability.id}>
                {formatAvailabilityDate(availability)}
              </Box>
            ))}
            <Box>...</Box>
          </Box>
        </Tooltip>
      );
    }

    return availabilities.map((availability) => (
      <div key={availability.id}>{formatAvailabilityDate(availability)}</div>
    ));
  };

  const renderInfoCell = (caregiver: User) => {
    let avatar = (
      <Avatar
        sizes="large"
        sx={{ m: 1, bgcolor: "primary.main", width: 60, height: 60 }}
      >
        <FaceIcon />
      </Avatar>
    );

    if (caregiver?.avatar) {
      avatar = (
        <Avatar
          sizes="large"
          sx={{ m: 1, bgcolor: "primary.main", width: 60, height: 60 }}
          src={caregiver?.avatar?.formats.thumbnail.url}
        />
      );
    }

    return (
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {avatar}
          <Box>
            <Typography variant="body1" fontSize="1.2rem">
              {caregiver?.first_name} {caregiver?.last_name}
            </Typography>
            <Typography variant="body2">{caregiver?.short_bio}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "auto",
              "& a": {
                margin: "0 5px",
                "& svg": {
                  fontSize: "1.5rem",
                  color: "primary.main",
                },
              },
            }}
          >
            {caregiver?.linkedin && (
              <a href={caregiver?.linkedin} target="_blank" rel="noreferrer">
                <LinkedInIcon />
              </a>
            )}
            {caregiver?.instagram && (
              <a
                href={`https://www.instagram.com/${caregiver?.instagram}`}
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon />
              </a>
            )}
          </Box>
        </Box>
      </TableCell>
    );
  };

  const renderTableRows = () => {
    if (!addressObj.address) {
      return (
        <FullWidthCenterCell>
          <FormattedMessage
            id="general.PleaseEnterLocation"
            defaultMessage="Please enter a location"
          />
        </FullWidthCenterCell>
      );
    }

    if (!availabilities.length) {
      return (
        <FullWidthCenterCell>
          <FormattedMessage
            id="general.NoCaregiversFoundPleaseChangeYourSearchCriteria"
            defaultMessage="No caregivers found. Please change your search criteria."
          />
        </FullWidthCenterCell>
      );
    }

    return availabilities.map((caregiver) => {
      let distanceTxt = "N/A";
      const distance = caregiver?.distance;

      if (distance) {
        if (distance === 1) {
          distanceTxt = `<${distance} km`;
        } else {
          distanceTxt = distance + " km";
        }
      }

      return (
        <TableRow key={caregiver.id}>
          {renderInfoCell(caregiver)}
          {Boolean(addressObj.address) && <TableCell>{distanceTxt}</TableCell>}
          <TableCell>{renderDates(caregiver.availabilities || [])}</TableCell>
          <TableCell align="right">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (caregiver) {
                  setSelectedCaregiver(caregiver);
                  setOpenModal(true);
                }
              }}
            >
              <FormattedMessage
                id="general.RequestBooking"
                defaultMessage="Request booking"
              />
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <Box>
      <Header />
      <Container sx={{ padding: "20px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h4" component="h6">
            <FormattedMessage
              id="general.FindMyCaregiver"
              defaultMessage="Find My Caregiver"
            />
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={1} mb={2}>
            <Grid item xs={6} sm={3}>
              <MobileDatePicker
                slots={{
                  textField: (params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        endAdornment: <EventIcon />,
                      }}
                    />
                  ),
                }}
                label={
                  <FormattedMessage id="general.From" defaultMessage="From" />
                }
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue || dayjs());

                  if (newValue?.isAfter(endDate)) {
                    setEndDate(newValue);
                  }
                }}
                className="default-picker-full-width"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <MobileDatePicker
                slots={{
                  textField: (params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        endAdornment: <EventIcon />,
                      }}
                    />
                  ),
                }}
                label={<FormattedMessage id="general.To" defaultMessage="To" />}
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue || dayjs());

                  if (newValue?.isBefore(startDate)) {
                    setStartDate(newValue);
                  }
                }}
                className="default-picker-full-width"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <GoogleAddressInput
                defaultValue={location}
                language={user?.language}
                onPlaceSelected={(place: any) => {
                  setAddressObj(place);
                  setLocation(place.address);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                type="number"
                label={
                  <FormattedMessage
                    id="general.Distance"
                    defaultMessage="Distance (km)"
                  />
                }
                inputProps={{
                  min: 1,
                }}
                value={radius || 1}
                onChange={(e) => {
                  setRadius(parseInt(e.target.value));
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                multiple
                limitTags={3}
                aria-multiline={false}
                id="services"
                options={allServices}
                getOptionLabel={(option) => option.name}
                value={selectedServices || []}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <FormattedMessage
                        id="general.Services"
                        defaultMessage="Services"
                      />
                    }
                  />
                )}
                onChange={(_event, value) => {
                  setSelectedServices(value);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                clearText={intl.formatMessage({
                  id: "general.Clear",
                  defaultMessage: "Clear",
                })}
                noOptionsText={intl.formatMessage({
                  id: "general.NoOptions",
                  defaultMessage: "No options",
                })}
              />
            </Grid>
          </Grid>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage
                    id="general.Caregiver"
                    defaultMessage="Caregiver"
                  />
                </TableCell>
                {Boolean(addressObj.address) && (
                  <TableCell>
                    <FormattedMessage
                      id="general.Distance"
                      defaultMessage="Distance"
                    />
                  </TableCell>
                )}
                <TableCell>
                  <FormattedMessage id="general.Dates" defaultMessage="Dates" />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTableRows()}</TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  count={pagination.total}
                  rowsPerPage={pagination.perPage}
                  page={pagination.page - 1}
                  onPageChange={(_event, page) => {
                    setPagination({
                      ...pagination,
                      page: page + 1,
                    });
                  }}
                  onRowsPerPageChange={(event) => {
                    setPagination({
                      ...pagination,
                      perPage: parseInt(event.target.value),
                    });
                  }}
                  labelDisplayedRows={({ from, to, count }) => {
                    if (intl.locale === "en") {
                      return `${from}-${to} of ${count}`;
                    } else {
                      return `${from}-${to} de ${count}`;
                    }
                  }}
                  labelRowsPerPage={
                    <FormattedMessage
                      id="general.RowsPerPage"
                      defaultMessage="Rows per page"
                    />
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Container>
      <BookCaregiverModal
        startDate={startDate}
        endDate={endDate}
        openModal={openModal}
        setOpenModal={setOpenModal}
        caregiver={selectedCaregiver}
        onBookingCreated={() => {
          const newAvailabilities = availabilities.map((availability) => {
            if (availability.id === selectedCaregiver?.id) {
              return {
                ...availability,
                booked: true,
              };
            }

            return availability;
          });

          setAvailabilities(newAvailabilities);
        }}
      />
    </Box>
  );
};

export default FindMyCaregiver;
