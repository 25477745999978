import { useContext } from "react";
import { AuthContext } from "../../context/auth";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../../Router";
import Header from "../components/Header";
import { UserRoleType } from "../../context/auth/types";

const Profile = () => {
  const { user } = useContext(AuthContext);

  if (user?.role.type === UserRoleType.CAREGIVER) {
    return <Navigate to={ROUTES.CAREGIVER_PROFILE} replace />;
  }

  if (user?.role.type === UserRoleType.CLIENT) {
    return <Navigate to={ROUTES.CLIENT_PROFILE} replace />;
  }

  return (
    <div>
      <Header />
      <h1>Profile</h1>
      <h4>
        Users can have euther a caregiver or client role. Please select one from
        Strapi admin panel.
      </h4>
      <p>
        This is temporary message. User role will be handled in future
        development
      </p>
    </div>
  );
};

export default Profile;
