import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockResetIcon from "@mui/icons-material/LockReset";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { CircularProgress } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import axios from "axios";

import MuiLink from "../components/MuiLink";
import { ROUTES } from "../../Router";

import PublicHeader from "../components/PublicHeader";
import PublicFooter from "../components/PublicFooter";

const SignIn = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  let [searchParams] = useSearchParams();

  const code = searchParams.get("code");

  const [codeOk, setCodeOk] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [passwordErr, setPasswordErr] = useState<string>("");
  const [passwordConfirmationErr, setPasswordConfirmationErr] =
    useState<string>("");

  const checkCodeReq = React.useCallback(
    async (code: string) => {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/auth/check-code?code=${code}`
      );

      if (res.data === "ok") {
        setCodeOk(true);
      } else {
        setCodeOk(false);
      }

      setLoading(false);
    },
    [setCodeOk, setLoading]
  );

  React.useEffect(() => {
    checkCodeReq(String(code));
  }, [checkCodeReq, code]);

  const resetPasswordReq = async (data: any) => {
    const res = await axios.post(
      `${process.env.REACT_APP_API}/auth/reset-password`,
      data
    );

    if (res.status !== 200) {
      setCodeOk(false);
    }

    navigate(ROUTES.LOGIN);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const password = data.get("password");
    const passwordConfirmation = data.get("passwordConfirmation");

    if (!password) {
      return setPasswordErr(
        intl.formatMessage({ id: "general.PasswordIsRequired" })
      );
    }

    if (password !== passwordConfirmation) {
      return setPasswordConfirmationErr(
        intl.formatMessage({ id: "general.PasswordsDontMatch" })
      );
    }

    resetPasswordReq({
      password,
      passwordConfirmation,
      code,
    });
  };

  const renderForm = () => {
    if (loading) {
      return (
        <Box
          style={{
            minHeight: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      );
    }

    if (!codeOk) {
      return (
        <Typography component="h2" variant="body1">
          <FormattedMessage
            id="general.ResetPasswordLinkInvalidOrExpired"
            defaultMessage="The reset password link is invalid or has expired. {link} to request a new one."
            values={{
              link: (
                <MuiLink variant="body2" to={ROUTES.FORGOT_PASSWORD}>
                  <FormattedMessage
                    id="general.ClickHere"
                    defaultMessage="Click here"
                  />
                </MuiLink>
              ),
            }}
          />
        </Typography>
      );
    }

    return (
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{
          "& > *": {
            margin: "5px 0 !important",
          },
        }}
      >
        <TextField
          margin="none"
          required
          fullWidth
          name="password"
          label={
            <FormattedMessage
              id="general.NewPassowrd"
              defaultMessage="New password"
            />
          }
          type="password"
          id="password"
          autoComplete="password"
          error={Boolean(passwordErr)}
          helperText={passwordErr}
          onChange={() => setPasswordErr("")}
        />
        <TextField
          margin="none"
          required
          fullWidth
          name="passwordConfirmation"
          label={
            <FormattedMessage
              id="general.ConfirmNewPassword"
              defaultMessage="Confirm new password"
            />
          }
          type="password"
          id="passwordConfirmation"
          autoComplete="current-password"
          error={Boolean(passwordConfirmationErr)}
          helperText={passwordConfirmationErr}
          onChange={() => setPasswordConfirmationErr("")}
        />

        <Button type="submit" fullWidth variant="contained" sx={{ mt: 2 }}>
          <FormattedMessage id="general.Submit" defaultMessage="Submit" />
        </Button>
      </Box>
    );
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <PublicHeader />
      <Container
        component="main"
        maxWidth="xs"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockResetIcon />
          </Avatar>
          <Typography component="h1" variant="h6">
            <FormattedMessage
              id="general.ResetMyPassword"
              defaultMessage="Reset my password"
            />
          </Typography>
          {renderForm()}
        </Box>
      </Container>
      <PublicFooter />
    </Box>
  );
};

export default SignIn;
