import { createTheme } from "@mui/material/styles";
import { frFR } from "@mui/x-date-pickers";

declare module "@mui/material/styles" {
  interface Theme {
    color: {
      primary: string;
      secondary: string;
      lightGrey: string;
      charcoal: string;
      lightGold: string;
      gold: string;
      redWine: string;
    };
    background: {
      pearl: string;
      black: string;
      dark: string;
      light: string;
    };
  }
  interface ThemeOptions {
    color?: {
      primary?: string;
      secondary?: string;
      lightGrey?: string;
      charcoal?: string;
      lightGold?: string;
      gold?: string;
      redWine?: string;
    };
    background?: {
      pearl?: string;
      black?: string;
      dark?: string;
      light?: string;
    };
  }
}

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#F1FCF8",
      },
    },
    color: {
      primary: "#000000",
      secondary: "#790000",
      lightGrey: "#F6F6F6",
      charcoal: "#343434",
      lightGold: "#F4D394",
      gold: "#B28735",
      redWine: "#790000",
    },
    background: {
      pearl: "#F1FCF8",
      black: "#000",
      dark: "#000",
      light: "#F1FCF8",
    },
    typography: {
      fontFamily: "'Source Sans 3', sans-serif",
      h1: {
        fontFamily: "'Cinzel', serif;",
        textTransform: "uppercase",
      },
      h2: {
        fontFamily: "'Cinzel', serif;",
        textTransform: "uppercase",
      },
      h3: {
        fontFamily: "'Cinzel', serif;",
        textTransform: "uppercase",
      },
      h4: {
        fontFamily: "'Cinzel', serif;",
        textTransform: "uppercase",
      },
      h5: {
        fontFamily: "'Cinzel', serif;",
        textTransform: "uppercase",
      },
      h6: {
        fontFamily: "'Cinzel', serif;",
        textTransform: "uppercase",
      },
    },
  },
  frFR
);

export default theme;
