import React, { FormEvent, useContext, useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { LockOutlined } from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import axios from "axios";

import MuiLink from "../components/MuiLink";
import { ROUTES } from "../../Router";
import { AuthContext } from "../../context/auth";
import PublicHeader from "../components/PublicHeader";
import PublicFooter from "../components/PublicFooter";
import { LayoutContext } from "../../context/layout";
import { Autocomplete } from "@mui/material";
import { Service } from "../../context/auth/types";
import GoogleAddressInput from "../components/GoogleAddressInput";
import ImageUploadInput from "../components/ImageUploadInput";
import {
  INSTAGRAM_USERNAME_REGEX,
  LINKEDIN_PROFILE_REGEX,
} from "../../constants";

const SignUp: React.FC<{ role: string }> = ({ role }) => {
  const { login } = useContext(AuthContext);
  const { language } = useContext(LayoutContext);
  const intl = useIntl();
  const isClient = role === "client";

  const userRoleId = isClient
    ? process.env.REACT_APP_CLIENT_ROLE_ID
    : process.env.REACT_APP_CAREGIVER_ROLE_ID;

  const [firstNameErr, setFirstNameErr] = useState<string>("");
  const [lastNameErr, setLastNameErr] = useState<string>("");
  const [signUpErr, setSignUpErr] = useState<string>("");
  const [emailValidationErr, setEmailValidationErr] = useState<string>("");
  const [passwordErr, setPasswordErr] = useState<string>("");
  const [passwordConfirmationErr, setPasswordConfirmationErr] =
    useState<string>("");
  const [allServices, setAllServices] = useState<Service[]>([]);
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);
  const [addressObj, setAddressObj] = useState<{
    address: string;
    latitude: number;
    longitude: number;
  }>({
    address: "",
    latitude: 0,
    longitude: 0,
  });
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [shortBioErr, setShortBioErr] = useState<string>("");
  const [instagramErr, setInstagramErr] = useState<string>("");
  const [linkedinErr, setLinkedinErr] = useState<string>("");

  const handleUpload = async (jwt: string) => {
    if (!selectedImage) return;

    const formData = new FormData();

    formData.append("files", selectedImage);

    const response = await axios.post(
      `${process.env.REACT_APP_API}/upload`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );

    const data = response.data;

    return data?.[0];
  };

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API}/services`
        );

        const services = data.data.map((service: any) => ({
          id: service.id,
          ...service.attributes,
        }));

        setAllServices(services);
      } catch (err: any) {
        console.error(err);
      }
    };

    fetchServices();
  }, [setAllServices]);

  const signUpRequest = async (data: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/auth/local/register`,
        data
      );

      const { jwt, user } = response.data;
      const me = await axios.get(`${process.env.REACT_APP_API}/users/me`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      const meData = me.data;

      if (!isClient) {
        const avatar = await handleUpload(jwt);

        if (avatar) {
          await axios.put(
            `${process.env.REACT_APP_API}/users/me`,
            {
              avatar,
            },

            {
              headers: {
                Authorization: `Bearer ${jwt}`,
              },
            }
          );

          meData.avatar = avatar;
        }
      }

      login({
        token: jwt,
        ...user,
        ...meData,
      });
    } catch (e: any) {
      const errMsg = e.response?.data?.error?.message;

      if (errMsg === "Email or Username are already taken") {
        return setEmailValidationErr(
          intl.formatMessage({
            id: "general.EmailAlreadyExists",
            defaultMessage: "Email already exists",
          })
        );
      }

      console.error(e);

      return setSignUpErr(
        intl.formatMessage(
          {
            id: "general.SomethingWentWrong",
            defaultMessage: "Something went wrong: {error}",
          },
          { error: errMsg }
        )
      );
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const password = data.get("password");
    const passwordConfirmation = data.get("passwordConfirmation");
    const email = data.get("email");
    const first_name = data.get("first_name");
    const last_name = data.get("last_name");
    const instagram = data.get("instagram");
    const linkedin = data.get("linkedin");
    const short_bio = data.get("short_bio");

    if (!first_name) {
      return setFirstNameErr(
        intl.formatMessage({ id: "general.FirstNameIsRequired" })
      );
    }

    if (!last_name) {
      return setLastNameErr(
        intl.formatMessage({ id: "general.LastNameIsRequired" })
      );
    }

    if (!email) {
      return setEmailValidationErr(
        intl.formatMessage({ id: "general.EmailIsRequired" })
      );
    }

    if (!password) {
      return setPasswordErr(
        intl.formatMessage({ id: "general.PasswordIsRequired" })
      );
    }

    if (password !== passwordConfirmation) {
      return setPasswordConfirmationErr(
        intl.formatMessage({ id: "general.PasswordsDontMatch" })
      );
    }

    if (instagram) {
      if (!INSTAGRAM_USERNAME_REGEX.test(String(instagram))) {
        return setInstagramErr(
          intl.formatMessage({
            id: "general.InvalidInstagramUsername",
            defaultMessage: "Invalid Instagram username",
          })
        );
      }
    }

    if (linkedin) {
      if (!LINKEDIN_PROFILE_REGEX.test(String(linkedin))) {
        return setLinkedinErr(
          intl.formatMessage({
            id: "general.InvalidLinkedInProfile",
            defaultMessage: "Invalid LinkedIn profile",
          })
        );
      }
    }

    if (!isClient && shortBioErr) {
      return;
    }

    const payload: any = {
      email,
      username: email,
      password,
      language,
      first_name,
      last_name,
      role: userRoleId,
      services: [],
      looking_for_services: [],
      instagram,
      linkedin,
      short_bio,
      ...addressObj,
    };

    const servicesIds = selectedServices.map((service) => service.id);

    if (servicesIds.length) {
      if (isClient) {
        payload.looking_for_services = servicesIds;
      } else {
        payload.services = servicesIds;
      }
    }

    signUpRequest(payload);
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <PublicHeader />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: "30px",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            {isClient ? (
              <FormattedMessage
                id="general.ClientSignup"
                defaultMessage="Client Signup"
              />
            ) : (
              <FormattedMessage
                id="general.CaregiverSignup"
                defaultMessage="Caregiver Signup"
              />
            )}{" "}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              "& > *": {
                margin: "5px 0 !important",
              },
            }}
          >
            <TextField
              margin="none"
              required
              fullWidth
              id="first_name"
              label={
                <FormattedMessage
                  id="general.FirstName"
                  defaultMessage="First Name"
                />
              }
              name="first_name"
              autoComplete="first_name"
              autoFocus
              error={Boolean(firstNameErr)}
              helperText={firstNameErr}
            />
            <TextField
              margin="none"
              required
              fullWidth
              id="last_name"
              label={
                <FormattedMessage
                  id="general.LastName"
                  defaultMessage="Last Name"
                />
              }
              name="last_name"
              autoComplete="last_name"
              error={Boolean(lastNameErr)}
              helperText={lastNameErr}
            />
            <TextField
              margin="none"
              required
              fullWidth
              id="email"
              label={
                <FormattedMessage id="general.Email" defaultMessage="Email" />
              }
              name="email"
              autoComplete="email"
              error={Boolean(emailValidationErr)}
              helperText={emailValidationErr}
              onChange={() => setEmailValidationErr("")}
            />
            {!isClient && (
              <Box>
                <GoogleAddressInput
                  defaultValue=""
                  onPlaceSelected={(place) => {
                    setAddressObj(place);
                  }}
                />
              </Box>
            )}
            <TextField
              margin="none"
              required
              fullWidth
              name="password"
              label={
                <FormattedMessage
                  id="general.Password"
                  defaultMessage="Password"
                />
              }
              type="password"
              id="password"
              autoComplete="current-password"
              error={Boolean(passwordErr)}
              helperText={passwordErr}
              onChange={() => setPasswordErr("")}
            />
            <TextField
              margin="none"
              required
              fullWidth
              name="passwordConfirmation"
              label={
                <FormattedMessage
                  id="general.ConfirmPassword"
                  defaultMessage="Confirm Password"
                />
              }
              type="password"
              id="passwordConfirmation"
              autoComplete="current-password"
              error={Boolean(passwordConfirmationErr)}
              helperText={passwordConfirmationErr}
              onChange={() => setPasswordConfirmationErr("")}
            />
            {!isClient && (
              <>
                <Autocomplete
                  multiple
                  id="services"
                  options={allServices}
                  getOptionLabel={(option) => option.name}
                  value={selectedServices}
                  filterSelectedOptions
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="none"
                      label={
                        <FormattedMessage
                          id="general.ServicesSelectAllThatApply"
                          defaultMessage="Services (select all that apply)"
                        />
                      }
                    />
                  )}
                  onChange={(event, value) => {
                    setSelectedServices(value);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  clearText={intl.formatMessage({
                    id: "general.Clear",
                    defaultMessage: "Clear",
                  })}
                  noOptionsText={intl.formatMessage({
                    id: "general.NoOptions",
                    defaultMessage: "No options",
                  })}
                />
                <ImageUploadInput
                  value={selectedImage?.name || ""}
                  label={intl.formatMessage({
                    id: "general.ProfileImage",
                    defaultMessage: "Profile Image",
                  })}
                  onChange={(e) => {
                    if (e.target.files) {
                      setSelectedImage(e.target.files[0]);
                    }
                  }}
                />
                <TextField
                  margin="none"
                  fullWidth
                  id="short_bio"
                  label={
                    <FormattedMessage
                      id="general.ShortBio"
                      defaultMessage="Short Bio"
                    />
                  }
                  name="short_bio"
                  autoComplete="short_bio"
                  autoFocus
                  error={Boolean(shortBioErr)}
                  helperText={shortBioErr}
                  onChange={(e: any) => {
                    if (e.target.value.length > 80) {
                      setShortBioErr(
                        intl.formatMessage({
                          id: "general.ShortBioMaxLength",
                          defaultMessage:
                            "Short bio max length is 80 characters",
                        })
                      );
                    } else {
                      setShortBioErr("");
                    }
                  }}
                />
                <TextField
                  margin="none"
                  fullWidth
                  id="instagram"
                  label={
                    <FormattedMessage
                      id="general.InstagramUsername"
                      defaultMessage="Instagram Username"
                    />
                  }
                  name="instagram"
                  autoComplete="instagram"
                  autoFocus
                  error={Boolean(instagramErr)}
                  helperText={instagramErr}
                />
                <TextField
                  margin="none"
                  fullWidth
                  id="linkedin"
                  label={
                    <FormattedMessage
                      id="general.LinkedInProfile"
                      defaultMessage="LinkedIn Profile"
                    />
                  }
                  name="linkedin"
                  autoComplete="linkedin"
                  autoFocus
                  error={Boolean(linkedinErr)}
                  helperText={linkedinErr}
                />
              </>
            )}

            {Boolean(signUpErr) && (
              <Typography style={{ color: "red" }}>{signUpErr}</Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 2,
              }}
            >
              <FormattedMessage id="general.SignUp" defaultMessage="Sign Up" />
            </Button>
            <MuiLink variant="body2" to={`${ROUTES.LOGIN}/${role}`}>
              <FormattedMessage
                id="general.AlreadyHaveAccount"
                defaultMessage="Already have an account?"
              />{" "}
              <FormattedMessage id="general.Login" defaultMessage="Login" />
            </MuiLink>
          </Box>
        </Box>
      </Container>
      <PublicFooter />
    </Box>
  );
};

export default SignUp;
