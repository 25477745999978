import { InputAdornment, TextField } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React from "react";

const ImageUploadInput = ({
  value,
  onChange,
  label,
  style = {},
  errorMessage,
}: {
  value: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  style?: React.CSSProperties;
  errorMessage?: string;
}) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      type="text"
      value={value || ""}
      style={style}
      label={label}
      onChange={onChange}
      error={!!errorMessage}
      helperText={errorMessage}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CloudUploadIcon />
            <input
              accept="image/*"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                opacity: 0,
              }}
              id="contained-button-file"
              multiple
              type="file"
              onChange={onChange}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default ImageUploadInput;
