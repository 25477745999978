import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import PublicHeader from "../components/PublicHeader";
import PublicFooter from "../components/PublicFooter";
import { FormattedMessage } from "react-intl";

const ForgotPassword = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <PublicHeader />

      <Container
        component="main"
        maxWidth="xs"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography component="h2" variant="body1">
              <FormattedMessage
                id="general.CheckEmailForLinkToResetPassword"
                defaultMessage="Please check your email for a link to reset your password"
              />
            </Typography>
          </Box>
        </Box>
      </Container>
      <PublicFooter />
    </Box>
  );
};

export default ForgotPassword;
