import { ThemeProvider } from "@mui/material";
import { IntlProvider } from "react-intl";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/fr";
import { enUS, frFR } from "@mui/x-date-pickers";

import languages, { LanguageCode } from "./languages";
import theme from "./theme";
import "./App.css";
import { useCallback, useContext, useEffect, useRef } from "react";
import { AuthContext } from "./context/auth";
import axios from "axios";
import Router from "./Router";
import { LayoutContext } from "./context/layout";

if (!process.env.REACT_APP_CLIENT_ROLE_ID) {
  throw new Error("REACT_APP_CLIENT_ROLE_ID is not defined");
} else if (!process.env.REACT_APP_CAREGIVER_ROLE_ID) {
  throw new Error("REACT_APP_CAREGIVER_ROLE_ID is not defined");
}

const getMessages = (langCode: LanguageCode) => {
  if (languages[langCode]) {
    return languages[langCode];
  }

  return languages.en;
};

function App() {
  const { user, logout, login } = useContext(AuthContext);
  const { language, setLanguage } = useContext(LayoutContext);
  const isMounted = useRef(false);
  const userLocale = user?.language as LanguageCode;
  const currentLanguate = userLocale || language || LanguageCode.en;

  if (user?.token) {
    axios.defaults.baseURL = process.env.REACT_APP_API;
    axios.defaults.headers.common = {
      Authorization: `Bearer ${user?.token}`,
    };
  } else {
    axios.defaults.baseURL = process.env.REACT_APP_API;
    axios.defaults.headers.common = {};
  }

  const getUser = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");

      if (token) {
        const data = await axios.get(`${process.env.REACT_APP_API}/users/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const user = data.data;

        setLanguage(user.language);

        login({
          token,
          ...user,
        });
      } else {
        const language = localStorage.getItem("language");

        if (
          [LanguageCode.en, LanguageCode.fr].includes(language as LanguageCode)
        ) {
          setLanguage(language as LanguageCode);
        }

        logout();
      }
    } catch (e) {
      console.error(e);

      logout();
    }
  }, [login, logout, setLanguage]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;

      getUser();
    }
  }, [getUser]);

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider
        locale={currentLanguate}
        defaultLocale="en"
        messages={getMessages(currentLanguate)}
      >
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={currentLanguate}
          localeText={
            currentLanguate === LanguageCode.en
              ? enUS.components.MuiLocalizationProvider.defaultProps.localeText
              : frFR.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <Router />
        </LocalizationProvider>
      </IntlProvider>
    </ThemeProvider>
  );
}

export default App;
