import { Autocomplete, Box, TextField } from "@mui/material";
import React from "react";

import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { FormattedMessage, useIntl } from "react-intl";

const GoogleAddressInput: React.FC<{
  defaultValue?: string;
  onPlaceSelected?: (place: any) => void;
  language?: string;
}> = ({ defaultValue, onPlaceSelected, language = "en" }) => {
  const intl = useIntl();
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    language,
  });

  const [address, setAddress] = React.useState<{
    description: string;
  }>({
    description: defaultValue || "",
  });

  const handlePlaceSelected = (value: any) => {
    if (value) {
      placesService?.getDetails(
        {
          placeId: value.place_id,
        },
        (placeDetails: any) => {
          onPlaceSelected?.({
            address: placeDetails.formatted_address,
            latitude: placeDetails.geometry.location.lat(),
            longitude: placeDetails.geometry.location.lng(),
          });

          getPlacePredictions({ input: "" });
          setAddress({
            description: placeDetails.formatted_address,
          });
        }
      );
    } else {
      onPlaceSelected?.({
        address: "",
        latitude: 0,
        longitude: 0,
      });
      getPlacePredictions({ input: "" });
      setAddress({
        description: "",
      });
    }
  };

  return (
    <Autocomplete
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      loading={isPlacePredictionsLoading}
      onChange={(e, value) => {
        handlePlaceSelected(value);
      }}
      filterOptions={(x) => x}
      options={placePredictions}
      isOptionEqualToValue={(option, value) => {
        return option.description === value.description;
      }}
      includeInputInList
      value={address}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={
            <FormattedMessage
              id="general.AddALocation"
              defaultMessage="Add a location"
            />
          }
          variant="outlined"
          onChange={(e) => {
            const value = e.target.value;

            getPlacePredictions({ input: value });
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            {option.description}
          </Box>
        );
      }}
      clearText={intl.formatMessage({
        id: "general.Clear",
        defaultMessage: "Clear",
      })}
      noOptionsText={intl.formatMessage({
        id: "general.NoOptions",
        defaultMessage: "No options",
      })}
    />
  );
};

export default GoogleAddressInput;
