import { Box, Button, Container } from "@mui/material";
import PublicHeader from "../components/PublicHeader";
import PublicFooter from "../components/PublicFooter";
import { FormattedMessage } from "react-intl";
import MuiLink from "../components/MuiLink";
import { ROUTES } from "../../Router";

const SelectRole: React.FC = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <PublicHeader />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: "30px",
          }}
        >
          <MuiLink to={`${ROUTES.LOGIN}/client`}>
            <Button variant="contained" color="primary" sx={{ mb: 2 }}>
              <FormattedMessage
                id="general.IamClient"
                defaultMessage="I am a client"
              />
            </Button>
          </MuiLink>
          <MuiLink to={`${ROUTES.LOGIN}/caregiver`}>
            <Button variant="contained" color="primary" sx={{ mb: 2 }}>
              <FormattedMessage
                id="general.IamCaregiver"
                defaultMessage="I am a caregiver"
              />
            </Button>
          </MuiLink>
        </Box>
      </Container>
      <PublicFooter />
    </Box>
  );
};

export default SelectRole;
