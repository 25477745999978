import { Box, CircularProgress } from "@mui/material";
// @mui/material/styles
import { useTheme } from "@mui/material/styles";

import PawsomeLogo from "../../assets/img/White/logo.png";

const SplashScreen = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        flexDirection: "column",
        padding: "0 15px",
      }}
    >
      <img
        src={PawsomeLogo}
        alt="Pawsome logo black"
        style={{
          width: "100%",
          maxWidth: 500,
        }}
      />
      <CircularProgress color="secondary" size={50} />
    </Box>
  );
};

export default SplashScreen;
