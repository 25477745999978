import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Box,
  CircularProgress,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import axios from "axios";
import { User } from "../../context/auth/types";
import dayjs from "dayjs";

const BookCaregiverModal: React.FC<{
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  caregiver: User | null;
  onBookingCreated?: (booking: any) => void;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
}> = ({
  openModal,
  setOpenModal,
  caregiver,
  onBookingCreated,
  startDate,
  endDate,
}) => {
  const intl = useIntl();
  const [message, setMessage] = useState<string>("");
  const [requestSent, setRequestSent] = useState<boolean>(false);
  const [laoding, setLoading] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string>("");

  const handleModalClose = () => {
    setOpenModal(false);
    setRequestSent(false);
    setMessage("");
    setErrMessage("");
  };

  const handleCreateRequest = async () => {
    if (!caregiver) {
      return;
    }

    try {
      setLoading(true);
      await axios.post("/bookings", {
        data: {
          caregiver: caregiver?.id,
          message,
          availability: caregiver?.availabilities?.[0]?.id,
          from_date: startDate.toDate(),
          to_date: endDate.toDate(),
        },
      });

      if (onBookingCreated) {
        onBookingCreated(caregiver);
      }

      setRequestSent(true);
    } catch (error: any) {
      const serverError = error.response?.data?.error;

      console.error(error);
      console.error(serverError);

      let message = intl.formatMessage({
        id: "general.SomethingWentWrong",
        defaultMessage: "Something went wrong",
      });

      if (serverError.message === "Booking created but email failed to send") {
        message = intl.formatMessage({
          id: "general.BookingCreatedButEmailFailedToSend",
          defaultMessage:
            "Booking created but email notification about your request failed to send",
        });

        setRequestSent(true);
      }

      setErrMessage(message);
    } finally {
      setLoading(false);
    }
  };

  if (!caregiver) {
    return null;
  }

  const renderContent = () => {
    if (laoding) {
      return (
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </DialogContent>
      );
    }

    if (requestSent || caregiver.booked) {
      return (
        <DialogContent>
          <Typography>
            <FormattedMessage
              id="general.RequestReceived"
              defaultMessage="Your request was received. You will hear from us soon."
            />
          </Typography>
          {errMessage && <Typography color="error">{errMessage}</Typography>}
        </DialogContent>
      );
    }

    const formatDateRange = () => {
      const start = intl.formatDate(startDate.toDate(), {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      const end = intl.formatDate(endDate.toDate(), {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      return `${start} - ${end}`;
    };

    return (
      <DialogContent>
        <Typography>
          <FormattedMessage
            id="general.RequestServiceFrom"
            defaultMessage="Request service from {name} for {date}"
            values={{
              name: caregiver?.first_name,
              date: formatDateRange(),
            }}
          />
        </Typography>
        <TextField
          id="message"
          label={intl.formatMessage({
            id: "general.Message",
            defaultMessage: "Message",
          })}
          multiline
          rows={4}
          variant="outlined"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        {errMessage && <Typography color="error">{errMessage}</Typography>}
      </DialogContent>
    );
  };

  const renderActions = () => {
    if (requestSent || caregiver.booked) {
      return (
        <DialogActions>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleModalClose();
            }}
            color="primary"
            variant="text"
          >
            <FormattedMessage id="general.Close" defaultMessage="Close" />
          </Button>
        </DialogActions>
      );
    }

    return (
      <DialogActions>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            handleModalClose();
          }}
          color="primary"
          variant="text"
        >
          <FormattedMessage id="general.Cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            handleCreateRequest();
          }}
          color="primary"
          variant="contained"
        >
          <FormattedMessage id="general.Request" defaultMessage="Request" />
        </Button>
      </DialogActions>
    );
  };

  return (
    <Dialog
      open={openModal}
      onClose={() => {
        handleModalClose();
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <FormattedMessage
          id="general.RequestService"
          defaultMessage="Request Service"
        />
      </DialogTitle>
      {renderContent()}
      {renderActions()}
    </Dialog>
  );
};

export default BookCaregiverModal;
