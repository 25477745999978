import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar as MuiAppBar,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Collapse,
} from "@mui/material";
import { Toolbar, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LanguageIcon from "@mui/icons-material/Language";
import { styled } from "@mui/material/styles";
import FaceIcon from "@mui/icons-material/Face";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import axios from "axios";
import { useIntl } from "react-intl";

import PawsomeLogo from "../../assets/img/White/logo.png";
import PawsomeLogoBlack from "../../assets/img/Black/logo.png";
import theme from "../../theme";
import { ROUTES } from "../../Router";
import { AuthContext } from "../../context/auth";
import MuiLink from "./MuiLink";
import { LanguageCode } from "../../languages";
import { LayoutContext } from "../../context/layout";

interface HeaderProps {
  enableBackBtn?: boolean;
  backButtonLink?: string;
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const Header = (props: HeaderProps) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { user, logout, updateUser } = useContext(AuthContext);
  const isCaregiver = user?.role.type === "caregiver";
  const isClient = user?.role.type === "client";
  const { setLanguage } = useContext(LayoutContext);

  const [open, setOpen] = React.useState(false);
  const [openLangCollapse, setOpenLangCollapse] =
    React.useState<boolean>(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChangeLanguage = async (langCode: LanguageCode) => {
    setLanguage(langCode);

    const response = await axios.put(`${process.env.REACT_APP_API}/users/me`, {
      language: langCode,
    });

    const uptUser = response.data;

    updateUser({
      ...user,
      ...uptUser,
    });

    setOpenLangCollapse(false);
  };

  return (
    <MuiAppBar
      position="absolute"
      elevation={0}
      sx={{
        position: "relative",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          backgroundColor: theme.background.dark,
        }}
      >
        <Box style={{ width: 200 }}>
          {Boolean(user) && (
            <Button onClick={handleDrawerOpen}>
              <MenuOpenIcon
                style={{
                  fontSize: 30,
                  color: theme.color.lightGrey,
                }}
              />
            </Button>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontWeight: "600",
          }}
        >
          <img style={{ width: 160 }} src={PawsomeLogo} alt="Pawsome logo" />
        </Box>
        <Box
          style={{ width: 200, justifyContent: "flex-end", display: "flex" }}
        >
          {Boolean(user) && props.enableBackBtn && (
            <IconButton
              onClick={() => {
                if (props.backButtonLink) {
                  navigate(props.backButtonLink);
                } else {
                  navigate(-1);
                }
              }}
            >
              <ArrowBackIcon
                style={{ color: theme.color.primary, fontSize: 30 }}
              />
            </IconButton>
          )}
        </Box>
      </Toolbar>
      <Box>
        <Drawer
          anchor="left"
          open={open}
          onClose={handleDrawerClose}
          className="app-main-drawer"
        >
          <Box>
            <DrawerHeader
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <MuiLink to={ROUTES.HOME} onClick={handleDrawerClose}>
                <img
                  style={{ width: 140, paddingLeft: 7 }}
                  src={PawsomeLogoBlack}
                  alt="Pawsome logo black"
                />
              </MuiLink>

              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              {isCaregiver && (
                <ListItem disablePadding>
                  <ListItemButton
                    selected={window.location.pathname === ROUTES.SCHEDULE}
                    onClick={() => {
                      navigate(ROUTES.SCHEDULE);
                    }}
                  >
                    <ListItemIcon>
                      <EditCalendarIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={intl.formatMessage({
                        id: "general.Schedule",
                        defaultMessage: "Schedule",
                      })}
                    />
                  </ListItemButton>
                </ListItem>
              )}

              {isClient && (
                <ListItem disablePadding>
                  <ListItemButton
                    selected={
                      window.location.pathname === ROUTES.FIND_MY_CAREGIVER
                    }
                    onClick={() => {
                      navigate(ROUTES.FIND_MY_CAREGIVER);
                    }}
                  >
                    <ListItemIcon>
                      <EditCalendarIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={intl.formatMessage({
                        id: "general.FindMyCaregiver",
                        defaultMessage: "Find my caregiver",
                      })}
                    />
                  </ListItemButton>
                </ListItem>
              )}

              {isCaregiver && (
                <ListItem disablePadding>
                  <ListItemButton
                    selected={window.location.pathname === ROUTES.TIMESHEETS}
                    onClick={() => {
                      navigate(ROUTES.TIMESHEETS);
                    }}
                  >
                    <ListItemIcon>
                      <WorkHistoryIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={intl.formatMessage({
                        id: "general.Timesheets",
                        defaultMessage: "Timesheets",
                      })}
                    />
                  </ListItemButton>
                </ListItem>
              )}

              <ListItem disablePadding>
                <ListItemButton
                  selected={[
                    ROUTES.CAREGIVER_PROFILE,
                    ROUTES.CLIENT_PROFILE,
                  ].includes(window.location.pathname as any)}
                  onClick={() => {
                    navigate(ROUTES.PROFILE);
                  }}
                >
                  <ListItemIcon>
                    <FaceIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={intl.formatMessage({
                      id: "general.Profile",
                      defaultMessage: "Profile",
                    })}
                  />
                </ListItemButton>
              </ListItem>
              <ListItemButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenLangCollapse(!openLangCollapse);
                }}
              >
                <ListItemIcon>
                  <LanguageIcon />
                </ListItemIcon>
                <ListItemText
                  primary={intl.formatMessage({
                    id: "general.Language",
                    defaultMessage: "Language",
                  })}
                />
                {openLangCollapse ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openLangCollapse} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeLanguage(LanguageCode.en);
                    }}
                  >
                    <ListItemText
                      primary={intl.formatMessage({
                        id: "general.English",
                        defaultMessage: "English",
                      })}
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeLanguage(LanguageCode.fr);
                    }}
                  >
                    <ListItemText
                      primary={intl.formatMessage({
                        id: "general.French",
                        defaultMessage: "French",
                      })}
                    />
                  </ListItemButton>
                </List>
              </Collapse>
            </List>
          </Box>
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={handleDrawerClose}
            onKeyDown={handleDrawerClose}
          >
            <Box
              onClick={(e) => e.stopPropagation()}
              style={{ padding: "0px 16px" }}
            >
              <Typography>{user?.email}</Typography>
            </Box>
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    logout();

                    navigate(ROUTES.LOGIN);
                  }}
                >
                  <ListItemIcon>
                    <ExitToAppOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={intl.formatMessage({
                      id: "general.Logout",
                      defaultMessage: "Logout",
                    })}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </Box>
    </MuiAppBar>
  );
};

export default Header;
