import React, { useContext } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import HomePage from "./pages/Home";
import SignInPage from "./pages/Login";
import SignUpPage from "./pages/SignUp";
import NotFoundPage from "./pages/NotFound";

import { AuthContext } from "./context/auth";
import SplashScreen from "./pages/components/Splash";
import Forbidden from "./pages/Forbidden";
import CaregiverProfile from "./pages/Profile/Caregiver";
import Profile from "./pages/Profile";
import { UserRoleType } from "./context/auth/types";
import TimesheetsPage from "./pages/Timesheets";
import SelectRolePage from "./pages/SelectRole";
import SchedulePage from "./pages/Schedule";
import FindMyCaregiver from "./pages/FindMyCaregiver";
import ForgotPasswordPage from "./pages/ForgotPassword";
import CheckEmailPage from "./pages/ForgotPassword/CheckEmail";
import ResetPasswordPage from "./pages/ForgotPassword/Reset";

export const ROUTES = {
  SELECT_ROLE: "/select-role",
  LOGIN: "/login",
  SIGN_UP: "/sign-up",
  HOME: "/",
  CAREGIVER_HOME: "/caregiver",
  CLIENT_HOME: "/client",
  PROFILE: "/profile",
  CAREGIVER_PROFILE: "/profile/caregiver",
  CLIENT_PROFILE: "/profile/client",
  TIMESHEETS: "/timesheets",
  SCHEDULE: "/schedule",
  FIND_MY_CAREGIVER: "/find-my-caregiver",
  FORGOT_PASSWORD: "/forgot-password",
  FORGOT_PASSWORD_CHECK_EMAIL: "/forgot-password/check-email",
  FORGOT_PASSWORD_RESET: "/forgot-password/reset",
} as const;

const ProtectedRoute: React.FC<{
  children: JSX.Element;
}> = ({ children }) => {
  const { user } = useContext(AuthContext);

  const location = useLocation();

  if (!user) {
    return (
      <Navigate to={ROUTES.SELECT_ROLE} state={{ from: location }} replace />
    );
  }

  return children;
};

const OnlyRoleRoute: React.FC<{
  role: UserRoleType;
  children: JSX.Element;
}> = ({ children, role }) => {
  const { user } = useContext(AuthContext);

  const location = useLocation();

  if (!user) {
    return <Navigate to={ROUTES.SIGN_UP} state={{ from: location }} replace />;
  }

  if (user?.role.type !== role) {
    return <Forbidden />;
  }

  return children;
};

const OnlyPublicRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const location = useLocation();
  const { user } = useContext(AuthContext);

  return user ? (
    <Navigate to={ROUTES.HOME} state={{ from: location }} replace />
  ) : (
    children
  );
};

const Router = () => {
  const timer = React.useRef<number | null>(null);
  const { loaded } = useContext(AuthContext);

  const [appLoaded, setAppLoaded] = React.useState(false);

  React.useEffect(() => {
    if (loaded) {
      timer.current = window.setTimeout(() => {
        setAppLoaded(true);
      }, 500);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [loaded]);

  if (!appLoaded) {
    return <SplashScreen />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.HOME} element={<HomePage />} />
        <Route
          path={ROUTES.SELECT_ROLE}
          element={
            <OnlyPublicRoute>
              <SelectRolePage />
            </OnlyPublicRoute>
          }
        />
        <Route path={`${ROUTES.SIGN_UP}`}>
          <Route
            index
            element={
              <Navigate
                to={ROUTES.SELECT_ROLE}
                replace
                state={{ from: ROUTES.SIGN_UP }}
              />
            }
          />
        </Route>
        <Route
          path={`${ROUTES.SIGN_UP}/client`}
          element={
            <OnlyPublicRoute>
              <SignUpPage role={UserRoleType.CLIENT} />
            </OnlyPublicRoute>
          }
        />
        <Route
          path={`${ROUTES.SIGN_UP}/caregiver`}
          element={
            <OnlyPublicRoute>
              <SignUpPage role={UserRoleType.CAREGIVER} />
            </OnlyPublicRoute>
          }
        />
        <Route
          path={`${ROUTES.LOGIN}`}
          element={
            <Navigate
              to={ROUTES.SELECT_ROLE}
              replace
              state={{ from: ROUTES.LOGIN }}
            />
          }
        />
        <Route
          path={`${ROUTES.LOGIN}/client`}
          element={
            <OnlyPublicRoute>
              <SignInPage role={UserRoleType.CLIENT} />
            </OnlyPublicRoute>
          }
        />
        <Route
          path={`${ROUTES.LOGIN}/caregiver`}
          element={
            <OnlyPublicRoute>
              <SignInPage role={UserRoleType.CAREGIVER} />
            </OnlyPublicRoute>
          }
        />

        <Route
          path={`${ROUTES.FORGOT_PASSWORD}`}
          element={
            <OnlyPublicRoute>
              <ForgotPasswordPage />
            </OnlyPublicRoute>
          }
        />

        <Route
          path={`${ROUTES.FORGOT_PASSWORD_CHECK_EMAIL}`}
          element={
            <OnlyPublicRoute>
              <CheckEmailPage />
            </OnlyPublicRoute>
          }
        />

        <Route
          path={`${ROUTES.FORGOT_PASSWORD_RESET}`}
          element={
            <OnlyPublicRoute>
              <ResetPasswordPage />
            </OnlyPublicRoute>
          }
        />

        <Route path={ROUTES.HOME}>
          <Route
            index
            element={
              <ProtectedRoute>
                <HomePage />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path={ROUTES.PROFILE}>
          <Route
            index
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path={ROUTES.CLIENT_PROFILE}>
          <Route
            index
            element={
              <OnlyRoleRoute role={UserRoleType.CLIENT}>
                {/* Temporary redirect to caregiver profile page
                    because as of right now the same page can be used for both
                */}
                {/* <ClientProfile /> */}
                <CaregiverProfile isClient={true} />
              </OnlyRoleRoute>
            }
          />
        </Route>
        <Route path={ROUTES.CAREGIVER_PROFILE}>
          <Route
            index
            element={
              <OnlyRoleRoute role={UserRoleType.CAREGIVER}>
                <CaregiverProfile />
              </OnlyRoleRoute>
            }
          />
        </Route>
        <Route path={ROUTES.TIMESHEETS}>
          <Route
            index
            element={
              <OnlyRoleRoute role={UserRoleType.CAREGIVER}>
                <TimesheetsPage />
              </OnlyRoleRoute>
            }
          />
        </Route>
        <Route path={ROUTES.SCHEDULE}>
          <Route
            index
            element={
              <OnlyRoleRoute role={UserRoleType.CAREGIVER}>
                <SchedulePage />
              </OnlyRoleRoute>
            }
          />
        </Route>
        <Route path={ROUTES.FIND_MY_CAREGIVER}>
          <Route
            index
            element={
              <OnlyRoleRoute role={UserRoleType.CLIENT}>
                <FindMyCaregiver />
              </OnlyRoleRoute>
            }
          />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
