import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useIntl } from "react-intl";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import PublicHeader from "../components/PublicHeader";
import PublicFooter from "../components/PublicFooter";
import { ROUTES } from "../../Router";

const ForgotPassword = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [signInErr, setSignInErr] = React.useState<string>("");

  const resetPasswordReq = async (data: any) => {
    await axios.post(`${process.env.REACT_APP_API}/auth/forgot-password`, data);

    navigate(ROUTES.FORGOT_PASSWORD_CHECK_EMAIL);
  };

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;

    return re.test(email);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const email = data.get("email");

    if (!email) {
      setSignInErr(
        intl.formatMessage({
          id: "general.EmailIsRequired",
          defaultMessage: "Email is required",
        })
      );
    } else if (!validateEmail(email as string)) {
      setSignInErr(
        intl.formatMessage({
          id: "general.InvalidEmail",
          defaultMessage: "Invalid email",
        })
      );
    }

    resetPasswordReq({
      email,
    });
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <PublicHeader />

      <Container
        component="main"
        maxWidth="xs"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={intl.formatMessage({
                id: "general.Email",
                defaultMessage: "Email",
              })}
              name="email"
              autoComplete="email"
              autoFocus
            />
            {Boolean(signInErr) && (
              <Typography style={{ color: "red" }}>{signInErr}</Typography>
            )}
            <Button type="submit" fullWidth variant="contained">
              {intl.formatMessage({
                id: "general.ResetMyPassword",
                defaultMessage: "Reset my password",
              })}
            </Button>
          </Box>
        </Box>
      </Container>
      <PublicFooter />
    </Box>
  );
};

export default ForgotPassword;
