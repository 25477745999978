import axios from "axios";

import { AuthAction, AuthState } from "./types";

const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("token", action.payload.user.token);

      return {
        ...state,
        loaded: true,
        isLoggedIn: true,
        user: action.payload.user,
      };
    case "LOGOUT":
      localStorage.removeItem("token");

      axios.defaults.baseURL = process.env.REACT_APP_API;
      axios.defaults.headers.common = {};

      return {
        ...state,
        loaded: true,
        isLoggedIn: false,
        user: null,
      };
    case "UPDATE_USER":
      return {
        ...state,
        user: action.payload.user,
      };
    default:
      return state;
  }
};

export default authReducer;
